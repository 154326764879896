import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleDoWithdraw = () => {
    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      console.log(data);
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message:
            "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal withdrawModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button
                      className="deposit-link"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-money-bill-alt"></i>
                      </div>
                      <p className="kr-text">입금신청</p>
                      <p className="en-text">Deposit</p>
                    </button>
                    <button
                      className="active"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-university"></i>
                      </div>
                      <p className="kr-text">출금신청</p>
                      <p className="en-text">Withdraw</p>
                    </button>
                    {/* <button
                      className="transfer-link"
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">금고</p>
                      <p className="en-text">Point</p>
                    </button> */}
                    <button
                      className="transfer-link"
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">콤프</p>
                      <p className="en-text">Comp</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">Withdraw</h5>
                      <h2 className="title">출금신청</h2>
                    </div>
                    <div className="modal-information mb-5">
                      <div className="icon-container">
                        <img src="img/icon/diamond-pink.png" />
                      </div>
                      <div className="text-container text-left">
                        <p>
                          <span className="highlight">출금주의사항 </span>
                        </p>
                        <p>
                          1. 입금자명과 출금자명이 다를 경우 본인확인 요청이
                          있을 수 있습니다.
                        </p>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-label">
                        <span className="labels blue">
                          <i className="fa fa-coins"></i> 보유머니 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      <div className="form-label">
                        <span className="labels yellow">
                          <i className="fa fa-won-sign"></i> 콤프 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.rolling)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>아이디</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>예금주명</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="labels">
                          <span>회원은행</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>신청금액</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              placeholder="0"
                              value={this.state.balance}
                              onFocus={() => this.setState({ balance: "" })}
                              onChange={(e) =>
                                this.setState({
                                  balance: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({ balance: "0" });
                              }}
                            >
                              정정
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 1000000
                                  }`,
                                });
                              }}
                            >
                              100만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 500000
                                  }`,
                                });
                              }}
                            >
                              50만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 100000
                                  }`,
                                });
                              }}
                            >
                              10만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 50000
                                  }`,
                                });
                              }}
                            >
                              5만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 10000
                                  }`,
                                });
                              }}
                            >
                              1만원
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn-style"
                        onClick={this.handleDoWithdraw}
                      >
                        출금신청
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
