import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  activeNote: boolean;
  
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
    };

    this.handleUpdateNote();

    // setImmediate(async () => {
    //   try {
    //     for (;;) {
    //       this.handleUpdateNote();

    //       await sleep(10000);
    //     }
    //   } catch (ex) {}
    // });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
        this.props.user.in_balance = 0;
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    if(this.props.activeNote !== false){
      return 
    }
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (this.props.activeNote) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        } 
        // if (this.state.postCount > 0) {
        //   this.setState({ popupStatuses: popupView.note });
        // }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="before-login active">
              <div className="desktop">
                <input
                  type="text"
                  placeholder="아이디"
                  onChange={({ target: { value } }) =>
                    this.setState({ ID: value })
                  }
                />
                <input
                  type="password"
                  placeholder="비밀번호"
                  onChange={({ target: { value } }) =>
                    this.setState({ PW: value })
                  }
                />
                <button
                  className="login-btn"
                  onClick={() => {
                    if (
                      this.state.ID == null ||
                      this.state.PW == null ||
                      this.state.ID === "" ||
                      this.state.PW === "" 
                    ) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => window.location.reload(),
                          },
                        ],
                      });
                      return;
                    }

                    this.props.tryLogin(this.state.ID, this.state.PW);
                  }}
                >
                  로그인
                </button>
                <button
                  className="blue-bg join-link"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.reg });
                  }}
                >
                  회원가입
                </button>
              </div>
              <div className="mobile">
                <button
                  className="mobile-btn login-link"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.login });
                  }}
                >
                  <i className="fas fa-unlock-alt"></i>
                </button>
                <button
                  className="mobile-btn blue-bg join-link"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.reg });
                  }}
                >
                  <i className="fas fa-user-plus"></i>
                </button>
              </div>
            </div>
          </>
        );
      }

      console.log(this.props.user)

      return (
        <>
          <div className="after-login active">
            <div className="desktop sidebar-right">
              <div className="al-cont user">
                <div className="cont labels">
                  <i className="fas fa-user-astronaut"></i>
                  <span>{this.props.user.id} 님</span>
                </div>
                <button
                  className="mypage-btn mypage-link"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.user }); //마이페이지
                  }}
                >
                  <i className="fa fa-cog"></i> 마이페이지
                </button>
              </div>
              <div className="al-cont">
                <div className="cont labels">
                  <i className="fa fa-coins"></i>
                  <span>보유머니(원)</span>
                </div>
                <div className="cont info">
                  <span>{ConverMoeny(this.props.user.balance + this.props.user.in_balance )}</span>
                </div>
              </div>
              <div className="al-cont">
                <div className="cont labels">
                  <i className="fas fa-money-bill-alt"></i>
                  <span>콤프(원)</span>
                </div>
                <div className="cont info">
                  <span>{ConverMoeny(this.props.user.rolling)}</span>
                </div>
              </div>
              {/* <div className="al-cont">
                <div className="cont labels">
                  <i className="fa fa-parking"></i>
                  <span>포인트</span>
                </div>
                <div className="cont info">
                  <span>8,888,000</span>
                </div>
              </div> */}
              <button
                className="logout-btn logout-desk"
                onClick={() => {
                  this.props.tryLoginOut();
                }}
              >
                <i className="fa fa-sign-out-alt"></i> <span>로그아웃</span>
              </button>
            </div>
            <div className="mobile">
              <button className="right-menu-btn mobile-btn">
                <i className="fa fa-user-cog norm-icon"></i>
                <i className="fas fa-chevron-right over-icon"></i>
              </button>
              <button
                className="mobile-btn logout-btn"
                onClick={() => {
                  this.props.tryLoginOut();
                }}
              >
                <i className="fa fa-sign-out-alt"></i>
              </button>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <header className="header-main">
          <div className="logo-container">
            <a
              href="javascript:;"
              className="logo"
              onClick={() => {
                window.location.reload();
              }}
            >
              <img className="pane" src="img/logo/pane.png" />
              <img className="seven" src="img/logo/seven.png" />
              {/* <img className="seven-icon" src="img/logo/seven-icon.png" />
              <img className="slot" src="img/logo/slot.png" /> */}
              <img className="fruits" src="img/logo/fruits.png" />
            </a>
            <button className="sound-btn active">
              <div className="pane">
                <i className="fa sound-icon fa-volume-up"></i>
              </div>
            </button>
          </div>
          <div className="container">
            <button className="left-menu-btn">
              <span></span>
              <span></span>
              <span></span>
            </button>
            <button className="sound-btn mobile active">
              <div className="pane">
                <i className="fa sound-icon fa-volume-up"></i>
              </div>
            </button>
            <ul className="bs-ul main-menu sidebar-left">
              <li>
                <div className="depth-btn">
                  <a
                    href="javascript:;"
                    className="link-a deposit-link"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.deposit }); //입금신청
                    }}
                  >
                    머니관련
                  </a>
                  <div className="drop-d">
                    <div className="drop-d-inner">
                      <a
                        href="javascript:;"
                        className="depth-a deposit-link"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.deposit }); //입금신청
                        }}
                      >
                        <div className="img-cont">
                          <i className="fa fa-download"></i>
                        </div>
                        <div className="text-cont">
                          <span>입금신청</span>
                        </div>
                      </a>
                      <a
                        href="javascript:;"
                        className="depth-a withdraw-link"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                        }}
                      >
                        <div className="img-cont">
                          <i className="fa fa-upload"></i>
                        </div>
                        <div className="text-cont">
                          <span>출금신청</span>
                        </div>
                      </a>
                 
                      <a
                        href="javascript:;"
                        className="depth-a transfer-link"
                        onClick={() => {
                          this.setState({ popupStatuses: popupView.comp }); //콤프
                        }}
                      >
                        <div className="img-cont">
                          <i className="fa fa-random"></i>
                        </div>
                        <div className="text-cont">
                          <span>콤프</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <a
                  href="javascript:;"
                  className="link-a notice-link"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  공지사항
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className={this.props.activeHelp ? "link-a customer-link neon" : "link-a customer-link "}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.help }); //고객센터
                  }}
                >
                  고객센터
                </a>
              </li>
            </ul>
            <div className="bal-container">{RenderLogin()}</div>
          </div>
        </header>

        <section className="banner-section">
          <div className="container">
            <div className="banner-background">
              <img className="robot" src="img/banner/robot.png" />
              <img className="month" src="img/banner/month.png" />
              <img className="glow-left" src="img/banner/glow-left.png" />
              <img className="event" src="img/banner/event.png" />
              <img className="gems" src="img/banner/gems.png" />
              <img className="glow-right" src="img/banner/glow-right.png" />
            </div>
          </div>
        </section>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
