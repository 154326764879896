import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleRollingToBalance = () => {
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal transferModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button
                      className="deposit-link"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-money-bill-alt"></i>
                      </div>
                      <p className="kr-text">입금신청</p>
                      <p className="en-text">Deposit</p>
                    </button>
                    <button
                      className="withdraw-link"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-university"></i>
                      </div>
                      <p className="kr-text">출금신청</p>
                      <p className="en-text">Withdraw</p>
                    </button>
                    {/* <button
                      className="transfer-link"
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">금고</p>
                      <p className="en-text">Point</p>
                    </button> */}
                    <button
                      className="active"
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">콤프</p>
                      <p className="en-text">Comp</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">Comp</h5>
                      <h2 className="title">콤프</h2>
                    </div>
                    {/* <div className="modal-information mb-5">
                      <div className="icon-container">
                        <img src="img/icon/diamond-blue.png" />
                      </div>
                      <div className="text-container text-left">
                        <p>
                          게임칩을 보유머니로 전환해야 환전
                          <span className="highlight">이 가능합니다.</span>
                        </p>
                      </div>
                    </div> */}
                    <div className="form-container">
                      <div className="form-label">
                        <span className="labels blue">
                          <i className="fa fa-coins"></i> 보유머니 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      <div className="form-label">
                        <span className="labels yellow">
                          <i className="fa fa-won-sign"></i> 콤프 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.rolling)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      {/* <div className="form-group">
                        <div className="labels">
                          <span>아이디</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>예금주명</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="form-group">
                        <div className="labels">
                          <span>회원은행</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="form-group">
                        <div className="labels">
                          <span>신청금액</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="0"
                              value={this.state.balance}
                              onFocus={() => this.setState({ balance: "" })}
                              onChange={(e) =>
                                this.setState({
                                  balance: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({ balance: "0" });
                              }}
                            >
                              정정
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 1000000
                                  }`,
                                });
                              }}
                            >
                              100만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 500000
                                  }`,
                                });
                              }}
                            >
                              50만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 100000
                                  }`,
                                });
                              }}
                            >
                              10만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 50000
                                  }`,
                                });
                              }}
                            >
                              5만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 10000
                                  }`,
                                });
                              }}
                            >
                              1만원
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn-style"
                        onClick={this.handleRollingToBalance}
                      >
                        콤프전환
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
