import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any) => {
    return (
      <>
        <li>
          <div className="con">
            <img
              src={`${info.imgUrl}`}
              style={{ width: "180px", height: "180px" }}
            />
            {/* <div className="title">
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginTop: "120px",
                }}
              >
                {info.nameKo}
              </p>
            </div> */}
            <a
              href="javascript:;"
              onClick={() => {
                this.handleOpenSlot(info.code, info.gameCompany);
              }}
            >
              <img src="/img/btn_game.png" />
            </a>
          </div>
        </li>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
    
            <div
              style={{
                width: "800px",
                height: "700px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
              }}
              className="container"
            >
              <div className="row">
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2 style={{ color: "rgb(248, 186, 65)" }}>
                        {this.props.gameName}
                      </h2>
                      <div className="hr-line-dashed"></div>

                      <div id="game_wrap">
                        <ul className="game">
                          {this.state.games.map((row: any) =>
                            this.RenderGame(row)
                          )}
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>

          
          </>
        )}
      </Popup>
    );
  }
}
