import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";

import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal transferModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">MyPage</h5>
                      <h2 className="title">마이페이지</h2>
                    </div>
                    <div className="form-container">
                      <div className="form-label">
                        <span className="labels blue">
                          <i className="fa fa-coins"></i> 보유머니 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      <div className="form-label">
                        <span className="labels yellow">
                          <i className="fa fa-won-sign"></i> 콤프 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.rolling)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>아이디</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>예금주명</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>회원은행</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>출금계좌번호</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.banknum}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group border-top">
                        <div className="labels"></div>
                        <div className="infos">
                          <div className="input-container"></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>새 비밀번호</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="새 비밀번호"
                              value={this.state.pass1}
                              onChange={(e: any) => {
                                this.setState({ pass1: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>비밀번호 확인</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="비밀번호 확인"
                              value={this.state.pass2}
                              onChange={(e: any) => {
                                this.setState({ pass2: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn-style"
                        onClick={this.handleExchangeToPassword}
                      >
                        비밀번호변경
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
